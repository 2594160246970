import React, { useEffect } from "react";
import { encode, decode } from "js-base64";
import "./App.css";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useFilePicker } from "use-file-picker";

function App() {
  const defaultStandard = 4;
  const defaultVoting = 0;
  const [yieldZone, setYieldZone] = React.useState(-1);
  const [season, setSeason] = React.useState(-1);
  const [moisture, setMoisture] = React.useState(-1);
  const [modelVersion, setModelVersion] = React.useState(defaultVoting);
  const [useVoting, setUseVoting] = React.useState(true);

  const standardOptions = (
    <>
      <option value="1">v1 - 2022-11-07</option>
      <option value="2">v2 - 2022-11-22</option>
      <option value="3">v3 - 2022-11-28</option>
      <option value="4">v4 - 2023-01-18</option>
    </>
  );
  const votingOptions = (
    <>
      <option value="0">v1 - 2023-01-31</option>
      <option value="2">v2 - 2023-06-09</option>
    </>
  );

  const allFieldsFilled = yieldZone !== -1 && season !== -1 && moisture !== -1;

  const bgColors = [
    "#deeaf6",
    "#bdd6ee",
    "#9cc2e5",
    "#79addd",
    "#5b9bd5",
    "#2e75b5",
    "#1e4e79",
    "#002060",
  ];
  const fgColors = [
    "black",
    "black",
    "black",
    "black",
    "black",
    "white",
    "white",
    "white",
  ];

  const [resp, setResp] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);
  const [openFileSelector, { filesContent, loading: fileLoading }] =
    useFilePicker({
      multiple: false,
    });
  const [didError, setDidError] = React.useState(false);

  const handleClick = async () => {
    openFileSelector();
  };

  const handleFetch = async () => {
    setLoading(true);
    setDidError(false);
    try {
      // Base64 encode the file content
      const content = filesContent[0].content;
      const base64 = encode(content);
      console.log(base64);
      // Post to http://localhost:5555/api/analyse with json body
      const response = await fetch(
        `https://pes.arcanaeum.me/api/analyse${useVoting ? "voting" : ""}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            file: base64,
            yield: yieldZone.toFixed(0),
            season: season.toFixed(0),
            moisture: moisture.toFixed(0),
            modelVersion,
          }),
        }
      );
      const data = await response.json();
      setResp(data);
    } catch (e) {
      console.log(e);
      setDidError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filesContent.length > 0) {
      console.log("filesContent", filesContent);
      void handleFetch();
    }
  }, [filesContent]);

  // Variables is an array of strings taken from resp, containing the names of the variables that end in "Bins"
  const indicators = Object.keys(resp || {});

  console.log("variables:", indicators);

  return (
    <div className="App">
      <Container>
        <FormControl as="fieldset">
          <FormLabel as="legend">Model Version</FormLabel>
          <Checkbox
            onChange={(e) => {
              setUseVoting(e.target.checked);
              setModelVersion(
                e.target.checked ? defaultVoting : defaultStandard
              );
            }}
            isChecked={useVoting}
          >
            Use voting Classifier
          </Checkbox>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setModelVersion(-1);
              } else {
                setModelVersion(val);
              }
            }}
            value={modelVersion}
          >
            {useVoting && votingOptions}
            {!useVoting && standardOptions}
          </Select>
        </FormControl>

        <FormControl as="fieldset">
          <FormLabel as="legend">Yield</FormLabel>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setYieldZone(-1);
              } else {
                setYieldZone(val);
              }
            }}
            placeholder={yieldZone === -1 ? "Select yield zone" : undefined}
          >
            <option value="0">Low</option>
            <option value="1">Medium</option>
            <option value="2">Reference</option>
          </Select>
        </FormControl>

        <FormControl as="fieldset">
          <FormLabel as="legend">Season</FormLabel>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setSeason(-1);
              } else {
                setSeason(val);
              }
            }}
            placeholder={season === -1 ? "Select season" : undefined}
          >
            <option value="0">Spring</option>
            <option value="1">Autumn</option>
            <option value="2">Summer</option>
            <option value="3">Winter</option>
          </Select>
          <FormHelperText>
            {season === 2 || season === 3
              ? "Summer and Winter results may be inaccurate due to lack of data"
              : ""}
          </FormHelperText>
        </FormControl>

        <FormControl as="fieldset">
          <FormLabel as="legend">Moisture</FormLabel>
          <Select
            onChange={(e) => {
              const val = parseInt(e.target.value);
              if (isNaN(val)) {
                setMoisture(-1);
              } else {
                setMoisture(val);
              }
            }}
            placeholder={moisture === -1 ? "Select moisture level" : undefined}
          >
            <option value="0">Dry</option>
            <option value="1">Moist</option>
            <option value="2">Wet</option>
          </Select>
        </FormControl>
        <br />
        <FormControl>
          <Tooltip label={!allFieldsFilled ? "Please fill all fields" : ""}>
            <Button
              isLoading={loading || fileLoading}
              onClick={handleClick}
              disabled={!allFieldsFilled}
            >
              Select File
            </Button>
          </Tooltip>
          {didError && (
            <FormHelperText color={"red"}>
              An error occurred. Please try again.
            </FormHelperText>
          )}
        </FormControl>
        <br />
      </Container>
      {resp && (
        <>
          {modelVersion !== 2 && (
            <Text>
              Results are presented as: [Mean], [Median], [Mode]
              <br />* indicates one or more voters could not identify the bin
            </Text>
          )}
          <TableContainer>
            <Table size={"sm"}>
              <Thead>
                <Tr>
                  <Th>&nbsp;</Th>
                  {indicators.map((indicator, i) => (
                    <Th key={i}>
                      {indicator.replace(/([A-Z])/g, " $1").trim()}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Index</Td>
                  {indicators.map((indicator, i) => {
                    // Remove "Bins" from the variable name and make sentence case - e.g. "extractableNitrateBins" becomes "Extractable Nitrate"
                    const indicatorName = indicator.replace(/([A-Z])/g, " $1");
                    return (
                      <Td
                        bgColor={bgColors[resp[indicator].mean]}
                        color={fgColors[resp[indicator].mean]}
                      >
                        {resp[indicator].mean !== -1 ? (
                          <p>
                            [{resp[indicator].mean + 1}]
                            {useVoting && modelVersion !== 2 && (
                              <span>
                                , [{resp[indicator].median + 1}], [
                                {resp[indicator].mode + 1}]
                                {resp[indicator].containsUnknown && "*"}
                              </span>
                            )}
                          </p>
                        ) : (
                          <p>Unknown</p>
                        )}
                      </Td>
                    );
                  })}
                </Tr>
                <Tr>
                  <Td />
                  {indicators.map((indicator, i) => (
                    <Td />
                  ))}
                </Tr>
                <Tr>
                  <Td>Key</Td>
                  {indicators.map((indicator, i) => (
                    <Td />
                  ))}
                </Tr>
              </Tbody>
              <Tfoot>
                {bgColors.map((bgColor, i) => {
                  if (i >= resp[indicators[0]].bins.length) {
                    return null;
                  }
                  return (
                    <Tr
                      key={`legend-${i}`}
                      bgColor={bgColor}
                      color={fgColors[i]}
                    >
                      <Td>[{i + 1}]</Td>
                      {indicators.map((indicator, j) => (
                        <Td>
                          {`${i === 0 ? "< " : ""}${
                            i === resp[indicator].bins.length - 1 ? "> " : ""
                          }${resp[indicator].bins[i === 0 ? 1 : i].toFixed(2)}${
                            i > 0 && i < resp[indicator].bins.length - 1
                              ? ` - ${resp[indicator].bins[i + 1].toFixed(2)}`
                              : ""
                          }`}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tfoot>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}

export default App;
